import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import Spinner from "../../../components/Spinner";
import { Breadcrumb, BreadcrumbItem } from "../../skus/components/breadcrumb";
import { Information, Summary, Values } from "../features/tcc-create";
import useAxios from "../../../utils/useAxios";
import AuthContext from "../../../context/AuthContext";
import { useLocation, useSearchParams } from "react-router-dom";
import { Predicates } from "../../../libraries/predicates/predicates";
import { InformationProps } from "../features/tcc-create/Information";
import { SummaryProps } from "../features/tcc-create/Summary";
import { ValuesProps } from "../features/tcc-create/Values";
import { useCreateTccStore } from "../../../context/CreateTccContext";
import useGlobalProduct from "../../../hooks/useGlobalProduct";
import useDesign from "../../../hooks/useDesign";
import useLicensedProperty from "../../../hooks/useLicensedProperty";
import {
  isTccInformationPageValid,
  isTccValuesPageValid,
  fetchRequest,
  fillStateWithRequestData,
  fetchRequestHistoryRecords,
  isMinimalTccInformationPageValid,
  getTccSuffixSuggestion,
} from "../../../helpers/create-tcc.helper";
import { CreateTccActionButtonsGroup } from "../components/create-tcc-action-buttons";
import { ITcc, ITccRequest, ITccRequestHistory } from "types/data.interface";
import { useRegionContext } from "../../../context/RegionContext";
import { CreateTccActions } from "../../../states/create-tcc";
import { useBeforeUnload } from "utils/useBeforeUnload";
import ProductContext, {
  ProductContextInterface,
} from "context/ProductContext";
import useCheckSimilarTccs from "hooks/useCheckSimilarTccs";
import Banner from "components/Banner";
import useTccApprovalLevelSequence from "../../../hooks/useTccApprovalLevelSequence";
import { fetchTccById } from "hooks/useTcc";

type CreateProps = {
  step: number;
  informationProps: InformationProps;
  valuesProps: ValuesProps;
  summaryProps: SummaryProps;
};

const Steps = ({
  step,
  informationProps,
  valuesProps,
  summaryProps,
}: CreateProps) => {
  switch (step) {
    case 1:
      return <Values {...valuesProps} />;
    case 2:
      return <Summary {...summaryProps} />;
    default:
      return <Information {...informationProps} />;
  }
};

const Create = () => {
  const axios = useAxios();
  const location = useLocation();
  const { state, dispatch } = useCreateTccStore();
  const { setUnsavedChanges } =
    useContext<ProductContextInterface>(ProductContext);
  const authCtx = useContext(AuthContext);
  const username = authCtx?.username;
  const hasPermission = authCtx?.hasPermission;
  const hasRegionalApproverPermission: boolean = hasPermission(
    "api.regional_approver_tcc_request",
  );
  const hasGlobalMarketApproverPermission: boolean = hasPermission(
    "api.global_market_approver_tcc_request",
  );
  const hasGlobalApproverPermission: boolean = hasPermission(
    "api.global_approver_tcc_request",
  );
  const hasAnyApproverPermission: boolean =
    hasRegionalApproverPermission ||
    hasGlobalMarketApproverPermission ||
    hasGlobalApproverPermission;

  const [isTccRequestInformationLoading, setIsTccRequestInformationLoading] =
    useState<boolean>(false);
  const [isSaveOrSubmitInProgress, setIsSaveOrSubmitInProgress] =
    useState<boolean>(false);
  const [step, setStep] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();
  const tccRequestId: string | null = useMemo(
    () => searchParams.get("request_id"),
    [searchParams],
  );
  const shouldCreate: string | null = useMemo(
    () => searchParams.get("should_create"),
    [searchParams],
  );
  const readOnly: string | null = useMemo(
    () => searchParams.get("read_only"),
    [searchParams],
  );
  const isEdit: string | null = useMemo(
    () => searchParams.get("is_edit"),
    [searchParams],
  );

  const isEditMode = useCallback(
    () =>
      Predicates.isNullOrUndefinedOrEmpty(isEdit) ||
      (Predicates.isNotNullAndNotUndefinedAndNotEmpty(isEdit) &&
        !hasGlobalApproverPermission),
    [isEdit, hasGlobalApproverPermission],
  );

  const isReadOnlyOrFinished = useCallback(
    () =>
      Predicates.isNotNullAndNotUndefinedAndNotEmpty(readOnly) ||
      state.requestStatus === "APPROVED" ||
      state.requestStatus === "REJECTED",
    [readOnly, state.requestStatus],
  );

  const isSubmittedAndNotRightApprover = useCallback(
    () =>
      state.requestStatus === "SUBMITTED" &&
      Predicates.isNotNullAndNotUndefined(state.currentApprovalLevel) &&
      (Predicates.isNullOrUndefinedOrEmpty(state.currentApprover) ||
        state.currentApprover !== username ||
        !hasAnyApproverPermission ||
        (hasRegionalApproverPermission &&
          state.currentApprovalLevel?.description !== "REGIONAL" &&
          !(
            hasGlobalMarketApproverPermission || hasGlobalApproverPermission
          )) ||
        (hasGlobalMarketApproverPermission &&
          state.currentApprovalLevel?.description !== "GLOBAL MKT" &&
          !(hasRegionalApproverPermission || hasGlobalApproverPermission)) ||
        (hasGlobalApproverPermission &&
          state.currentApprovalLevel?.description !== "GLOBAL" &&
          !(
            hasRegionalApproverPermission || hasGlobalMarketApproverPermission
          ))),
    [
      state.requestStatus,
      state.currentApprovalLevel,
      state.currentApprover,
      username,
      hasAnyApproverPermission,
      hasRegionalApproverPermission,
      hasGlobalMarketApproverPermission,
      hasGlobalApproverPermission,
    ],
  );

  const isCreatedAndNotRightRequester = useCallback(
    () =>
      state.requestStatus === "CREATED" &&
      Predicates.isNotNullAndNotUndefinedAndNotEmpty(state.requester) &&
      state.requester !== username,
    [state.requestStatus, state.requester, username],
  );

  const isReadOnlyRequest: boolean = useMemo(
    () =>
      isEditMode() &&
      (isReadOnlyOrFinished() ||
        isSubmittedAndNotRightApprover() ||
        isCreatedAndNotRightRequester()),
    [
      isEditMode,
      isReadOnlyOrFinished,
      isSubmittedAndNotRightApprover,
      isCreatedAndNotRightRequester,
    ],
  );

  const { data: regions } = useRegionContext();

  const { data: globalProducts, isLoading: isGlobalProductsLoading } =
    useGlobalProduct({
      category: state.subCategory?.parent?.id ?? state.category?.id,
      sub_category: state.subCategory?.id,
      product_line: state.productLine?.id,
      production_lifecycle_status:
        isEdit && state.productionLifecycleStatus === "Active"
          ? "Active"
          : undefined,
      sales_lifecycle_status:
        isEdit && state.salesLifecycleStatus === "Active"
          ? "Active"
          : undefined,
    });

  const { data: designs, isLoading: isDesignsLoading } = useDesign({
    decorationTechniqueId: state.decorationTechnique?.id ?? "",
  });

  const { data: licensedProperties, isLoading: isLicensedPropertiesLoading } =
    useLicensedProperty({
      licensorId: state.licensor?.id?.toString() ?? "",
    });

  const { data: existsSimilarTccs } = useCheckSimilarTccs({
    globalProductId: state.globalProduct?.id ?? null,
    productColorId: state.productColor?.id ?? null,
    artworkId: state.artwork?.unique_id ?? null,
    piecesInSellingUnits: state.pcsSellingUnits,
    shouldCheck: !isReadOnlyRequest,
    tccId: state.tccId ?? null,
    isGlobalApprover: hasGlobalApproverPermission,
  });

  const { data: approvalLevels, isLoading: isApprovalLevelsLoading } =
    useTccApprovalLevelSequence({
      shouldCheck: step === 2 && !isReadOnlyRequest,
      state: state,
      user: username ?? "",
    });

  const isStepDisabled = useCallback(
    (step: number): boolean => {
      let shouldBeDisabled: boolean = true;
      switch (step) {
        case 0:
          shouldBeDisabled =
            (!isTccInformationPageValid(state) &&
              hasGlobalApproverPermission) ||
            !isMinimalTccInformationPageValid(state) ||
            isReadOnlyRequest;
          break;
        case 1:
          shouldBeDisabled = !isTccValuesPageValid() || isReadOnlyRequest;
          break;
        case 2:
          shouldBeDisabled = false;
          break;
        default:
          shouldBeDisabled = false;
      }
      return step === 0
        ? shouldBeDisabled
        : shouldBeDisabled || isStepDisabled(step - 1);
    },
    [state, isReadOnlyRequest],
  );

  const onNext = useCallback(
    (specificStep?: number) => {
      setStep(
        Predicates.isNotNullAndNotUndefined(specificStep)
          ? specificStep
          : step + 1,
      );
    },
    [step],
  );

  const onPrevious = useCallback(() => {
    setStep(step - 1);
  }, [step]);

  useEffect(() => {
    const loadTccRequest = async () => {
      if (
        Predicates.isNullOrUndefined(shouldCreate) &&
        Predicates.isNotNullAndNotUndefinedAndNotEmpty(tccRequestId)
      ) {
        setIsTccRequestInformationLoading(true);
        const [request, historyRecords] = await Promise.all<
          [Promise<ITccRequest | null>, Promise<ITccRequestHistory[]>]
        >([
          fetchRequest(axios, tccRequestId),
          fetchRequestHistoryRecords(axios, tccRequestId),
        ]);

        const tcc: ITcc | null =
          isEdit &&
          Predicates.isNotNullAndNotUndefined(request?.corresponding_object_id)
            ? await fetchTccById({
                id: String(request?.corresponding_object_id),
                axios,
              })
            : null;

        fillStateWithRequestData(request, historyRecords, tcc, dispatch);

        if (
          Predicates.isNotNullAndNotUndefined(request) &&
          Predicates.isNotNullAndNotUndefined(request.data.globalProduct) &&
          (request?.status === "SUBMITTED" || request?.status === "CREATED") &&
          hasGlobalApproverPermission &&
          !isReadOnlyRequest
        ) {
          dispatch(
            CreateTccActions.setTccSuffix(
              await getTccSuffixSuggestion(request.data.globalProduct, axios),
            ),
          );
        } else {
          dispatch(
            CreateTccActions.setTccSuffix(request?.data.tccSuffix ?? ""),
          );
        }
        dispatch(CreateTccActions.setUnsavedChanges(false));
        setIsTccRequestInformationLoading(false);
      } else {
        dispatch(CreateTccActions.resetAllData());
        setStep(0);
      }
    };
    loadTccRequest();
  }, [location, isReadOnlyRequest]);

  useEffect(() => {
    setStep(isReadOnlyRequest ? 2 : 0);
  }, [isReadOnlyRequest]);

  useEffect(() => {
    const globalRegion = regions.find((r) => r.name === "GLOBAL MKT");
    if (Predicates.isNotNullAndNotUndefined(globalRegion)) {
      dispatch(
        CreateTccActions.addRegionComposition({
          region: globalRegion,
          isRestrictive: false,
          restrictions: [],
        }),
      );
    }
  }, [location, regions]);

  useEffect(() => {
    if (!isReadOnlyRequest) {
      setUnsavedChanges({
        existsUnsavedChanges: state.hasUnsavedChanges,
        message:
          "You may have unsaved changes. Are you sure you want to leave?",
      });
    }
  }, [state.hasUnsavedChanges, isReadOnlyRequest]);

  useBeforeUnload({
    condition: !isReadOnlyRequest && state.hasUnsavedChanges,
    message: "You may have unsaved changes. Are you sure you want to leave?",
  });

  return (
    <>
      {isTccRequestInformationLoading ? (
        <Spinner />
      ) : (
        <div>
          <Breadcrumb>
            <BreadcrumbItem
              text="1 - TCC Information"
              isDisabled={isSaveOrSubmitInProgress || isReadOnlyRequest}
              onClick={() => {
                onNext(0);
              }}
              showActive={true}
              isCurrent={step === 0}
            />
            <BreadcrumbItem
              text="2 - TCC Data"
              isDisabled={isStepDisabled(0) || isSaveOrSubmitInProgress}
              onClick={() => {
                onNext(1);
              }}
              showActive={step >= 1}
              isCurrent={step === 1}
            />
            <BreadcrumbItem
              text="3 - Summary"
              isDisabled={
                isReadOnlyRequest
                  ? false
                  : isStepDisabled(1) || isSaveOrSubmitInProgress
              }
              onClick={() => {
                onNext(2);
              }}
              showActive={step >= 2}
              isCurrent={step === 2}
            />
          </Breadcrumb>

          <Steps
            step={step}
            {...{
              informationProps: {
                isReadOnlyRequest,
                globalProducts,
                isGlobalProductsLoading,
                designs,
                isDesignsLoading,
                licensedProperties,
                isLicensedPropertiesLoading,
                isCountryReadOnly:
                  state.requestStatus === "SUBMITTED" || isReadOnlyRequest,
                isEdit: Predicates.isNotNullAndNotUndefined(isEdit),
                hasGlobalApproverPermission,
              },

              valuesProps: {},

              summaryProps: {
                isReadOnlyRequest,
                isEdit: Predicates.isNotNullAndNotUndefined(isEdit),
              },
            }}
          />

          {step === 2 &&
            !isApprovalLevelsLoading &&
            approvalLevels.length > 0 &&
            approvalLevels.some((al) => al.description === "GLOBAL MKT") && (
              <Banner
                classname={"mt-3"}
                message={`This request will need to receive approval from a GLOBAL MKT approver`}
                type="info"
              />
            )}
          {existsSimilarTccs && (
            <Banner
              classname={"mt-3"}
              message={`Another TCC(s) with the same ${hasGlobalApproverPermission ? "product color," : ""} artwork and number of pieces in
            selling units already exist for this Global Product`}
              type="warning"
            />
          )}

          <CreateTccActionButtonsGroup
            step={step}
            isReadOnlyRequest={isReadOnlyRequest}
            isEdit={Predicates.isNotNullAndNotUndefinedAndNotEmpty(isEdit)}
            isSaveOrSubmitInProgress={isSaveOrSubmitInProgress}
            username={username ?? ""}
            hasRegionalApproverPermission={hasRegionalApproverPermission}
            hasGlobalMarketApproverPermission={
              hasGlobalMarketApproverPermission
            }
            hasGlobalApproverPermission={hasGlobalApproverPermission}
            onNext={onNext}
            onPrevious={onPrevious}
            isStepDisabled={isStepDisabled}
            setIsSaveOrSubmitInProgress={setIsSaveOrSubmitInProgress}
          />
        </div>
      )}
    </>
  );
};

export default Create;
