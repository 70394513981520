const LinePlanningStatusBreakdownTooltip = (props: any) => {
  return (
    <div className="d-flex flex-column grid-tooltip">
      <span>
        <b>Production Lifecycle Status: </b>
        {props.data.production_lifecycle_status}
      </span>
      <span>
        <b>Sales Lifecycle Status: </b>
        {props.data.sales_lifecycle_status}
      </span>
    </div>
  );
};

export default LinePlanningStatusBreakdownTooltip;
