import { faCirclePlus, faImage } from "@fortawesome/free-solid-svg-icons";
import { memo, useCallback, useContext, useEffect, useState } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import ButtonIconModal from "../../../../components/ButtonIconModal";
import OptionFormatter from "../../../../components/OptionFormatter";
import ProductMasterTable from "../../../../components/ProductMasterTable";
import { useDatacatalogContext } from "../../../../context/DatacatalogContext";
import {
  DebounceContext,
  DebounceContextType,
} from "../../../../context/DebounceContext";
import { usePageableTable } from "../../../../context/PageableTableContext";
import {
  formatDatacalogIntoOption,
  getDatacatalog,
} from "../../../../helpers/datacatalog.helper";
import {
  formatGlobalProductIntoOption,
  getGlobalProduct,
} from "../../../../helpers/global-product.helper";
import { formatSkuIntoOption, getSku } from "../../../../helpers/sku.helper";
import {
  formatTccCompositionRegionInfoIntoOption,
  getSelectableTccCompositionRegions,
} from "../../../../helpers/tcc-composition-region-info";
import { formatTccIntoOption, getTcc } from "../../../../helpers/tcc.helper";
import useGlobalProduct, {
  fetchGlobalProduct,
} from "../../../../hooks/useGlobalProduct";
import { ProductMaster } from "../../../../hooks/useProductMaster";
import useSku, { fecthSku } from "../../../../hooks/useSku";
import useTcc, { fetchTcc } from "../../../../hooks/useTcc";
import { Predicates } from "../../../../libraries/predicates/predicates";
import { PageableTableActions } from "../../../../states/pageable-table";
import {
  IGlobalProduct,
  IProductHier,
  ISkuInformation,
  ITcc,
  PisRequestStatus,
  Sku,
  TccCompositionRegionInfo,
} from "../../../../types/data.interface";
import { NEW_PIS_HIERARCHY_PREFIX } from "../../../../types/persistent-page-filters";
import useAxios from "../../../../utils/useAxios";
import {
  GlobalProductTemplate,
  TCCTemplate,
} from "../../components/attributes-modal";
import { PictureModal } from "../../components/modals";
import { getLifecycleStatusRestriction } from "./sku-create.helper";

export type HierarchyProps = {
  selectedSku: Sku | null;
  setSelectedSku: (pm: Sku | null) => void;
  selectedPM: ProductMaster | null;
  setSelectedPM: (pm: ProductMaster | null) => void;
  selectedGP: IGlobalProduct | null;
  setSelectedGP: (gp: IGlobalProduct | null) => void;
  selectedTcc: ITcc | null;
  setSelectedTcc: (tcc: ITcc | null) => void;
  requestStatus: PisRequestStatus;
  requestRevision: string;
  setUnsavedChangesToTrue: () => void;
  selectedTccCompositionRegion: TccCompositionRegionInfo | null;
  setSelectedTccCompositionRegion: (
    tccCompositionRegion: TccCompositionRegionInfo | null,
  ) => void;
  selectableTccCompositionRegions: TccCompositionRegionInfo[] | null;
  skuData: ISkuInformation;
  setSkuData: (data: ISkuInformation) => void;

  setChangedImportValues: (didChange: boolean) => void;
  setSelectedTccCompositionRows: (rows: number[]) => void;
  setSelectedMoldIndexes: (indexes: string[]) => void;
};

const Hierarchy = memo(
  ({
    selectedSku,
    setSelectedSku,
    selectedPM,
    setSelectedPM,
    selectedGP,
    setSelectedGP,
    selectedTcc,
    setSelectedTcc,
    requestStatus,
    requestRevision,
    setUnsavedChangesToTrue,
    selectedTccCompositionRegion,
    setSelectedTccCompositionRegion,
    selectableTccCompositionRegions,
    skuData,
    setSkuData,
    setChangedImportValues,
    setSelectedTccCompositionRows,
    setSelectedMoldIndexes,
  }: HierarchyProps) => {
    const [sku, setSku] = useState<Sku | null>(selectedSku ?? null);
    const [category, setCategory] = useState<IProductHier | null>(
      selectedGP?.sub_category.parent ?? null,
    );
    const [subCategory, setSubCategory] = useState<IProductHier | null>(
      selectedGP?.sub_category ?? null,
    );
    const [productLine, setProductLine] = useState<IProductHier | null>(
      selectedGP?.product_line ?? null,
    );
    const [globalProduct, setGlobalProduct] = useState<IGlobalProduct | null>(
      selectedGP ?? null,
    );
    const [tcc, setTcc] = useState<ITcc | null>(selectedTcc ?? null);

    const { searchDebounce } = useContext<DebounceContextType>(DebounceContext);
    const { state: pageableTableState, dispatch } = usePageableTable();

    const axios = useAxios();

    const { data: skus, isLoading: isSkusLoading } = useSku({
      search: selectedTcc ? selectedTcc.id?.toString() : undefined,
    });

    const { datacatalog, isLoading: isDatacatalogLoading } =
      useDatacatalogContext();

    const { data: globalProducts, isLoading: isGlobalProductsLoading } =
      useGlobalProduct({
        category: subCategory?.parent?.id ?? category?.id,
        sub_category: subCategory?.id,
        product_line: productLine?.id,
        production_lifecycle_status: getLifecycleStatusRestriction(
          requestRevision,
          skuData.production_lifecycle_status,
        ),
        sales_lifecycle_status: getLifecycleStatusRestriction(
          requestRevision,
          skuData.sales_lifecycle_status,
        ),
      });

    const { data: tccs, isLoading: isTccsLoading } = useTcc({
      category: subCategory?.parent?.id ?? category?.id,
      sub_category: subCategory?.id,
      product_line: productLine?.id,
      global_product: globalProduct?.id,
      isPisPage: true,
      production_lifecycle_status: getLifecycleStatusRestriction(
        requestRevision,
        skuData.production_lifecycle_status,
      ),
      sales_lifecycle_status: getLifecycleStatusRestriction(
        requestRevision,
        skuData.sales_lifecycle_status,
      ),
    });

    const handleSku = (item: any) => {
      setSku(item?.value ?? null);
      setSelectedSku(item?.value ?? null);

      if (Predicates.isNotNullAndNotUndefined(item?.value?.tcc))
        handleTcc({ value: item?.value?.tcc ?? null });
      else if (Predicates.isNotNullAndNotUndefined(item?.value.global_product))
        handleGlobalProduct({
          value: item?.value.global_product,
        });
      else resetAllHierarchyFields();

      if (item && (item.value?.tcc || item.value?.global_product)) {
        setSelectedPM({
          id: item.value.id,
          description: item.value.name.trim(),
          image_file: item.value.image_file,
          inventory: item.value.invetory,
          mold_qty: item.value.mold_qty,
          stdpk: item.value.stdpk,
        });
      }

      setUnsavedChangesToTrue();
    };

    const handleCategory = (item: any) => {
      setCategory(item?.value ?? item);
      setSubCategory(null);
      resetGlobalProduct();

      setUnsavedChangesToTrue();
    };

    const handleSubCategory = (item: any) => {
      setSubCategory(item?.value ?? null);
      if (Predicates.isNotNullAndNotUndefined(item?.value)) {
        setCategory(item.value.parent);
      }
      resetGlobalProduct();

      setUnsavedChangesToTrue();
    };

    const handleProductLine = (item: any) => {
      setProductLine(item?.value ?? null);
      resetGlobalProduct();

      setUnsavedChangesToTrue();
    };

    const handleGlobalProduct = (item: any, isInitialChange?: boolean) => {
      setGlobalProduct(item?.value ?? null);
      setSelectedGP(item?.value ?? null);
      if (Predicates.isNotNullAndNotUndefined(item?.value)) {
        setSubCategory(item?.value.sub_category);
        setProductLine(item?.value.product_line);
        setCategory(item?.value.sub_category.parent);
      } else {
        resetAllHierarchyFields();
      }
      setTcc(null);
      setSelectedTcc(null);
      setSelectedTccCompositionRegion(null);
      resetSkuLifecycleStatus(item.value);

      setChangedImportValues(true);
      setSelectedTccCompositionRows([]);
      setSelectedMoldIndexes([]);

      if (!isInitialChange) setUnsavedChangesToTrue();
    };

    const handleTccCompositionRegion = (item: any) => {
      setSelectedPM(null);
      setSelectedTccCompositionRegion(item.value ?? item);

      setChangedImportValues(true);
      setSelectedTccCompositionRows([]);
      setSelectedMoldIndexes([]);

      setUnsavedChangesToTrue();
    };

    const handleTcc = (item: any, isInitialChange?: boolean) => {
      if (Predicates.isNotNullAndNotUndefined(item?.value)) {
        if (item.value.suffix !== "8888") {
          setTcc(item.value);
          setSelectedTcc(item.value);
          setSelectedTccCompositionRegion(null);
        }

        setSelectedGP(item.value.global_product);
        setGlobalProduct(item.value.global_product);
        setSubCategory(item.value.global_product?.sub_category);
        setProductLine(item.value.global_product?.product_line);
        setCategory(item.value.global_product?.sub_category?.parent);
        resetSkuLifecycleStatus(item.value);
      } else {
        setTcc(null);
        setSelectedTcc(null);
        setSelectedTccCompositionRegion(null);
        resetAllHierarchyFields();
      }

      setChangedImportValues(true);
      setSelectedTccCompositionRows([]);
      setSelectedMoldIndexes([]);

      if (!isInitialChange) setUnsavedChangesToTrue();
    };

    const resetGlobalProduct = () => {
      setGlobalProduct(null);
      setTcc(null);
      setSelectedTcc(null);
      setSelectedTccCompositionRegion(null);
      setSelectedGP(null);

      setSelectedPM(null);
    };

    const resetAllHierarchyFields = () => {
      setSubCategory(null);
      setProductLine(null);
      setCategory(null);
      resetGlobalProduct();
    };

    const resetSkuLifecycleStatus = (item: ITcc | IGlobalProduct) => {
      const shouldResetProductionStatus =
        item.production_lifecycle_status === "Inactive";
      const shouldResetSalesStatus = item.sales_lifecycle_status === "Inactive";
      setSkuData({
        ...skuData,
        ...(shouldResetProductionStatus && {
          production_lifecycle_status: "Inactive",
        }),
        ...(shouldResetSalesStatus && { sales_lifecycle_status: "Inactive" }),
      });
    };

    const globalProductOptions = async (search: string, callback: any) => {
      if (Predicates.isNullOrUndefined(search) || search.length < 3) return [];
      const response = await fetchGlobalProduct({
        category: subCategory?.parent?.id ?? category?.id,
        sub_category: subCategory?.id,
        product_line: productLine?.id,
        search,
        production_lifecycle_status: getLifecycleStatusRestriction(
          requestRevision,
          skuData.production_lifecycle_status,
        ),
        sales_lifecycle_status: getLifecycleStatusRestriction(
          requestRevision,
          skuData.sales_lifecycle_status,
        ),
        axios,
      });

      callback(
        getGlobalProduct(response, {
          subCategory: subCategory?.id,
          productLine: productLine?.id,
          id: globalProduct?.id,
        }),
      );
    };

    const tccOptions = async (search: string, callback: any) => {
      if (Predicates.isNullOrUndefined(search) || search.length < 3) return [];
      const response = await fetchTcc({
        category: subCategory?.parent?.id ?? category?.id,
        sub_category: subCategory?.id,
        product_line: productLine?.id,
        global_product: globalProduct?.id,
        search,
        isPisPage: true,
        production_lifecycle_status: getLifecycleStatusRestriction(
          requestRevision,
          skuData.production_lifecycle_status,
        ),
        sales_lifecycle_status: getLifecycleStatusRestriction(
          requestRevision,
          skuData.sales_lifecycle_status,
        ),
        axios,
      });
      callback(getTcc(response));
    };

    const skuOptions = async (search: string, callback: any) => {
      if (Predicates.isNullOrUndefined(search) || search.length < 3) return [];
      const response = await fecthSku({
        search,
        axios,
      });
      callback(getSku(response));
    };

    const onSelectionChanged = useCallback((value: ProductMaster) => {
      setSelectedPM(value);
    }, []);

    useEffect(() => {
      if (pageableTableState.offset !== 0)
        dispatch(PageableTableActions.setOffset(0));
    }, [globalProduct?.id, tcc?.id, selectedPM?.id]);

    const getTccCompositionRegionValue = () => {
      return selectedTccCompositionRegion
        ? formatTccCompositionRegionInfoIntoOption(selectedTccCompositionRegion)
        : null;
    };

    const getTccCompositionRegionOptions = () => {
      return selectableTccCompositionRegions
        ? getSelectableTccCompositionRegions(selectableTccCompositionRegions)
        : [];
    };

    return (
      <>
        <h6 className="text-primary fw-bold card-header border-0 mb-2">
          SKU Hierarchy
        </h6>
        <div className="mb-2 row">
          <div
            className="col-auto d-flex flex-column justify-content-start"
            style={{ marginTop: 2 }}
          >
            <label className="form-label">Clone hierarchy from SKU:</label>
          </div>
          <div className="col-sm-6 col-md-5">
            <div
              className={`d-flex align-items-center ${
                Predicates.isNotNullAndNotUndefined(sku) &&
                Predicates.isNullOrUndefined(sku?.global_product)
                  ? "is-invalid"
                  : ""
              }`}
            >
              <span className="form-label mr-2">11 </span>
              <AsyncSelect
                name="sku"
                value={sku ? formatSkuIntoOption(sku) : null}
                onChange={handleSku}
                defaultOptions={getSku(skus)}
                loadOptions={(input, callback) => {
                  searchDebounce(skuOptions, input, getSku(skus), callback);
                }}
                formatOptionLabel={OptionFormatter}
                isLoading={isSkusLoading}
                cacheOptions
                isClearable
                isSearchable
                placeholder="(min 3 digits)"
                classNamePrefix="react-select"
                className={`flex-fill
                ${
                  Predicates.isNotNullAndNotUndefined(sku) &&
                  Predicates.isNullOrUndefined(sku?.global_product)
                    ? "is-invalid"
                    : ""
                }
                  `}
              />
            </div>
            <div className="invalid-feedback ml-4">
              This SKU has no Global Product associated, please select another
              SKU to clone the hierarchy.
            </div>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-6 col-md-4">
            <label className="form-label">
              Category <span className="red-text fw-bold">*</span>
            </label>
            <Select
              name="category"
              value={category ? formatDatacalogIntoOption(category) : null}
              onChange={handleCategory}
              options={getDatacatalog(datacatalog, { type: "Category" })}
              formatOptionLabel={OptionFormatter}
              isLoading={isDatacatalogLoading}
              isClearable
              isSearchable
              placeholder=""
              classNamePrefix="react-select"
            />
          </div>
          <div className="col-6 col-md-4">
            <label className="form-label">
              Sub-Category <span className="red-text fw-bold">*</span>
            </label>
            <Select
              name="subCategory"
              value={
                subCategory ? formatDatacalogIntoOption(subCategory) : null
              }
              onChange={handleSubCategory}
              options={getDatacatalog(datacatalog, {
                type: "Sub-Category",
                categoryId: category?.id,
              })}
              formatOptionLabel={OptionFormatter}
              isLoading={isDatacatalogLoading}
              isClearable
              isSearchable
              placeholder=""
              classNamePrefix="react-select"
            />
          </div>
          <div className="col-6 col-md-4">
            <label className="form-label">
              Product Line <span className="red-text fw-bold">*</span>
            </label>
            <Select
              name="productLine"
              value={
                productLine ? formatDatacalogIntoOption(productLine) : null
              }
              onChange={handleProductLine}
              options={getDatacatalog(datacatalog, { type: "Product Line" })}
              formatOptionLabel={OptionFormatter}
              isLoading={isDatacatalogLoading}
              isClearable
              isSearchable
              placeholder=""
              classNamePrefix="react-select"
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-6">
            <label className="form-label">
              Global Product Code / Name{" "}
              <span className="red-text fw-bold">*</span>
            </label>
            {Predicates.isNotNullAndNotUndefined(globalProduct) ? (
              <ButtonIconModal
                buttonIconClass="mx-3 d-inline-block"
                description="show attributes"
                title={`Global Product ${
                  formatGlobalProductIntoOption(globalProduct).label
                }`}
                icon={faCirclePlus}
                iconClass="me-2"
              >
                <GlobalProductTemplate model={globalProduct} />
              </ButtonIconModal>
            ) : (
              <></>
            )}
            <AsyncSelect
              name="globalProduct"
              value={
                globalProduct
                  ? formatGlobalProductIntoOption(globalProduct)
                  : null
              }
              onChange={(e) => handleGlobalProduct(e)}
              defaultOptions={getGlobalProduct(globalProducts, {
                subCategory: subCategory?.id,
                productLine: productLine?.id,
                id: globalProduct?.id,
              })}
              loadOptions={(input, callback) => {
                searchDebounce(
                  globalProductOptions,
                  input,
                  getGlobalProduct(globalProducts, {
                    subCategory: subCategory?.id,
                    productLine: productLine?.id,
                    id: globalProduct?.id,
                  }),
                  callback,
                );
              }}
              formatOptionLabel={OptionFormatter}
              isLoading={isGlobalProductsLoading}
              isClearable
              isSearchable
              placeholder="(min 3 digits)"
              classNamePrefix="react-select"
            />
          </div>
          <div className="col-6">
            <label className="form-label">
              TCC Code / Name
              {requestStatus === "SUBMITTED" && (
                <span className="red-text fw-bold">*</span>
              )}
            </label>
            {Predicates.isNotNullAndNotUndefined(tcc) ? (
              <>
                <ButtonIconModal
                  buttonIconClass="mx-3 d-inline-block"
                  description="show attributes"
                  title={`TCC ${formatTccIntoOption(tcc).label}`}
                  icon={faCirclePlus}
                  iconClass="me-2"
                >
                  <TCCTemplate tccId={tcc.id} />
                </ButtonIconModal>
                <ButtonIconModal
                  buttonIconClass="mx-3 d-inline-block"
                  description="show picture"
                  title={`TCC ${formatTccIntoOption(tcc).label}`}
                  icon={faImage}
                  iconClass="me-2"
                >
                  <PictureModal src={tcc.product_photo} />
                </ButtonIconModal>
              </>
            ) : (
              <></>
            )}
            <AsyncSelect
              name="tcc"
              value={tcc ? formatTccIntoOption(tcc) : null}
              onChange={(e) => handleTcc(e)}
              defaultOptions={getTcc(tccs)}
              formatOptionLabel={OptionFormatter}
              loadOptions={(input, callback) => {
                searchDebounce(tccOptions, input, getTcc(tccs), callback);
              }}
              isLoading={isTccsLoading}
              isClearable
              isSearchable
              placeholder="(min 3 digits)"
              classNamePrefix="react-select"
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-6"></div>
          <div className="col-6">
            <label className="form-label">
              TCC Composition Region{" "}
              {Predicates.isNotNullAndNotUndefined(tcc?.id) &&
                Predicates.isNotNullAndNotUndefinedAndNotEmpty(
                  selectableTccCompositionRegions,
                ) && <span className="red-text fw-bold">*</span>}
            </label>
            <Select
              name="tccCompositionRegion"
              value={getTccCompositionRegionValue()}
              onChange={handleTccCompositionRegion}
              options={getTccCompositionRegionOptions()}
              formatOptionLabel={OptionFormatter}
              isSearchable
              placeholder=""
              classNamePrefix="react-select"
              isDisabled={
                !(
                  Predicates.isNotNullAndNotUndefined(tcc?.id) &&
                  Predicates.isNotNullAndNotUndefinedAndNotEmpty(
                    selectableTccCompositionRegions,
                  )
                )
              }
            />
          </div>
        </div>

        {Predicates.isNotNullAndNotUndefined(globalProduct?.id) ||
        Predicates.isNotNullAndNotUndefined(tcc?.id) ? (
          <>
            <h6 className="text-primary card-header fw-bold  border-0 mb-1">
              {`SKUs included in the selected ${
                Predicates.isNotNullAndNotUndefined(tcc)
                  ? `TCC: ${formatTccIntoOption(tcc).label}`
                  : `Global Product: ${
                      globalProduct
                        ? formatGlobalProductIntoOption(globalProduct).label
                        : ""
                    }`
              } ${
                pageableTableState.total
                  ? "(" + pageableTableState.total + ")"
                  : ""
              }`}
            </h6>

            <ProductMasterTable
              localStoragePrefix={NEW_PIS_HIERARCHY_PREFIX}
              checkUnsaved={true}
              filters={{
                globalProductId: globalProduct?.id,
                tccId: tcc?.id,
                productMasterId: selectedPM?.id,
              }}
              onSelectionChanged={onSelectionChanged}
              enableAdvancedFilters={true}
              showClearButton={false}
              showCustomFields={["tcc_name"]}
              showSelectColumn={
                selectedTccCompositionRegion
                  ? !selectedTccCompositionRegion.is_restrictive
                  : selectableTccCompositionRegions?.length === 0 &&
                    selectedTcc !== null
              }
            />
          </>
        ) : (
          <></>
        )}
      </>
    );
  },
);

export default Hierarchy;
